import './../css/bump.css';
import './../css/pieces.css';
import React, { Component } from 'react';
import {motion} from "framer-motion";

class GamesHeader extends Component {
    
        getClasses(piece, currentSelectedPiece, playerColor) {
        // arrow <-- this gives the shape (e.g only two borders of the div). Selectors are always an arrow.
        // left, right, up, down <-- the rotation of the div
        // player1, player2 <-- color
        // selected <-- green background
        
        // const playerobj = this.props.getWhoseTurn[0];
        // const player = playerobj.name.toLowerCase();
        const selected = (piece === currentSelectedPiece) ? "selected" : "notselected";
        
        let classes = ""
        switch(piece){
          case "x":
            classes = "xHeader " + selected
            break;
          default:
            classes = "arrow " + piece + " " + playerColor + " " + selected
        };
        return classes
      };

    headerText = () => {
      // if there's a winner, figure out if its P1 or P2 and return that    
      if (this.props.currentGame.p1_user_id === this.props.currentGame.winner) {
        return (<span>{this.props.currentGame.p1_name} is the winner</span>) 
      };
      if (this.props.currentGame.p2_user_id === this.props.currentGame.winner) {
        return (<span>{this.props.currentGame.p2_name} is the winner</span>) 
      };
      
      // if there is not a winner, and it's the logged on user's turn, figure out if they are P1 or P2 
      // and return their name and the fact that they need to select a piece
      if (this.props.getSessionObj.user_id === this.props.currentGame.turn_user_id) {
        if (this.props.currentGame.turn_user_id === this.props.currentGame.p1_user_id){
          return (<span> {this.props.currentGame.p1_name}: Select a piece to add: </span>)
        };
      };
      if (this.props.getSessionObj.user_id === this.props.currentGame.turn_user_id) {
        if (this.props.currentGame.turn_user_id === this.props.currentGame.p2_user_id){
          return (<span> {this.props.currentGame.p2_name}: Select a piece to add: </span>)
        };
      };
      
      // if there is not a winner, and it's not the logged on user's turn, figure out if they are P1 or P2 
      // and return the fact that we are waiting for the other guy to make a move
      if (this.props.getSessionObj.user_id !== this.props.currentGame.turn_user_id) {
        if (this.props.currentGame.turn_user_id === this.props.currentGame.p1_user_id){
          return (<span>Waiting for {this.props.currentGame.p1_name} to make a move</span> )
        };
      };
      if (this.props.getSessionObj.user_id !== this.props.currentGame.turn_user_id) {
        if (this.props.currentGame.turn_user_id === this.props.currentGame.p2_user_id){
          return (<span>Waiting for {this.props.currentGame.p2_name} to make a move</span> )
        };
      };
    }
    
    render() { 
      // const playerobj = this.props.getWhoseTurn[0];
      const currentGame = this.props.currentGame;
      const sessionObj=this.props.getSessionObj;
      // const deletes=this.props.getDeletes;
      const deletes=currentGame.deletes;
      var currentPlayerName = null;
      var currentSelectedPiece = null;
      var playerColor = null;
      if (currentGame.turn_user_id === currentGame.p1_user_id) {
        currentPlayerName = currentGame.p1_name;
        currentSelectedPiece = currentGame.p1_selected_piece;
        playerColor = "purple";
      };
      if (currentGame.turn_user_id === currentGame.p2_user_id) {
        currentPlayerName = currentGame.p2_name
        currentSelectedPiece = currentGame.p2_selected_piece;
        playerColor = "orange";
      };
      
      // This section is about getting the correct sizing for the "loading" rectangle that fits
      // exactly on top of the piece selectors 
      // which has to he rendered with inline styling because it changes depending on screen width
      var lsr = null
      var lsrl = null
      var lsrt = null
      var lsrb = null

      // These selectors only exist when you are actully in a game, therefore:
      const ls = document.getElementById("left");
      if (ls !==null) {
        lsr = ls.getBoundingClientRect();
        lsrl = lsr.left;
        lsrt = lsr.top;
      }
      
      var ndr = null;
      var ndrr = null;
      const ndSelector = document.getElementById("numDeletes");
      if (ndSelector !==null) {
        ndr=ndSelector.getBoundingClientRect();
        ndrr=ndr.right
      }
      
      var cr = null;
      var crh = null;
      const cSelector = document.getElementById("commands");
      if (cSelector !==null) {
        cr=cSelector.getBoundingClientRect();
        crh=cr.height
      }
      
      var loadingWidth = ndrr - lsrl;
      
      var ourTurn = false
      if (sessionObj.user_id === currentGame.turn_user_id) {ourTurn = true};
      if (ourTurn) {
        return (
            <div className = "header">
            <div id="commands" className="commands">
              {this.headerText()}
              {this.props.boardHeaderLoading ? (<span className ="isLoading" style={{left:lsrl, top:lsrt, height:crh-1, width:loadingWidth }}>Loading...</span>) : null}
              <motion.div 
                id="left"
                whileHover={currentSelectedPiece==="left"  ? {scale:1}: {scale:1.2} } 
                style={{rotate: 135}}
                onClick={ currentSelectedPiece === "left" ? () => {} : () => this.props.onSelectPiece("left") } 
                className={this.getClasses("left", currentSelectedPiece, playerColor)} 
              ></motion.div>
              <motion.div 
                id="right"
                style={{rotate: -45}}
                whileHover={currentSelectedPiece==="right" ? {scale:1}: {scale:1.2} } 
                onClick={currentSelectedPiece === "right" ? () => {} : () => this.props.onSelectPiece("right") } 
                className={this.getClasses("right", currentSelectedPiece, playerColor)}  
              ></motion.div>
              <motion.div 
                id="up"
                style={{rotate: -135}}
                whileHover={currentSelectedPiece==="up"  ? {scale:1}: {scale:1.2} } 
                onClick={currentSelectedPiece === "up" ? () => {} : () => this.props.onSelectPiece("up") } 
                className={this.getClasses("up", currentSelectedPiece, playerColor)} 
              ></motion.div>
              <motion.div 
                id="down"
                style={{rotate: 45}}
                whileHover={currentSelectedPiece==="down" ? {scale:1}: {scale:1.2} } 
                onClick={currentSelectedPiece === "down" ? () => {} :  () => this.props.onSelectPiece("down") } 
                className={this.getClasses("down", currentSelectedPiece, playerColor)}  
                ></motion.div>
              <span> or remove: </span>
              <motion.span
                id="x"
                >
                <img 
                  className={this.getClasses("x", currentSelectedPiece)}  
                  alt = "Delete selector"
                  src = "../images/x.svg"
                  // you can't click on it if there are no deletes left
                  onClick={ deletes > 0 && currentSelectedPiece!=="x" ? () => this.props.onSelectPiece("x") : () => {}} 
                  ></img>
              </motion.span>
              <span id="numDeletes"> {deletes} </span>
            </div>
            </div>
        );
    } else {
      return (
        <div className = "header">
            <div className="commands">
              {this.headerText()}
            </div>
        </div>
        )
    }
    
    } 

}

export default GamesHeader; 

