import React, { Component } from 'react';
import './../css/bump.css';

class NotFound extends Component {   
    render() { 
        return (
            <React.Fragment>

            <h2 > 
                <br/>
                404 Not Found
            </h2>
            </React.Fragment>
        )
    };
    // end of Component
}
 
export default NotFound;