export function getIndexSquareUp(board,x,y){
  const yUp = y => {
      if (y>0) { y--} 
      return y;
      };
  const posUp = x + "-" + yUp(y);
  return board.findIndex(position => position.pos === posUp);
  };

export function getIndexSquareRight(board,x,y){
  const xRight = x => {
    if (x<19) { x++} 
    return x;
    };
  const posRight = xRight(x) + "-" + y;
  return board.findIndex(position => position.pos === posRight);
  };

export function getIndexSquareDown(board,x,y){
  const yDown = y => {
    if (y<19) { y++} 
    return y;
    };
  const posDown = x + "-" + yDown(y);
  return board.findIndex(position => position.pos === posDown);
  };
    
export function getIndexSquareLeft(board,x,y){
  const xLeft = x => {
    if (x>0) { x--} 
    return x;
    };
  const posLeft = xLeft(x) + "-" + y;
  return board.findIndex(position => position.pos === posLeft);
  };

export function setUpBoard(boardSize){
  // this has a bug: When you click NewGame, it doesn't reset the state of all the squares
  // which means the center four squares, if clicked on, will not change a
  let board = []
  let square = {}
  for (let y = 0; y < boardSize ; y++) {
      for (let x = 0; x < boardSize ; x++) {
        square = {pos: x+"-"+y, piece: "empty", playerId:0}  
        board.push(square);
      }; // end of x
  }; // end of y
  return board
  };

export function setUpPlayers(){
  return [
    {id:1, name: "Player1", selectedPiece: "up", turn: "yep"},
    {id:2, name: "Player2", selectedPiece: "up", turn: "nope"}
  ]
};

