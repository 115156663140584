import React, { Component } from 'react';
import './../css/bump.css';

class InvitationsOverview extends Component {   
    render() {
        const {to_unread, to_read, to_accepted, to_declined, from_unread, from_read, from_accepted, from_declined} = this.props.invitationCounts
        if(this.props.getLoggedIn){
            return (
                <React.Fragment>
                <div className= "indent sentInvitations">
                        <div className= "raleway invitationsBox ">
                            <span>Here's an overview of all your invitations: </span>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Read</th>
                                        <th>Unread</th>
                                        <th>Accepted</th>
                                        <th>Declined</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Received:</td>
                                        <td>{to_read}</td>
                                        <td>{to_unread}</td>
                                        <td>{to_accepted}</td>
                                        <td>{to_declined}</td>
                                    </tr>
                                    <tr>
                                        <td>Sent:</td>
                                        <td>{from_read}</td>
                                        <td>{from_unread}</td>
                                        <td>{from_accepted}</td>
                                        <td>{from_declined}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                </div>    
                </React.Fragment>
            )
        }
    };
    // end of Component
}
 
export default InvitationsOverview;