import React, { Component } from 'react';
import './../css/bump.css';

class AfterLogIn extends Component {   
    render() { 
        return (
            <React.Fragment>
                <div className="endOfLogin">
                    <p>Now that you are logged in:</p>
                    <li>Check the navigation at the top </li>
                    <li>Invitations: This is where you start. Invite people to play, and/or accept their invitations.</li>
                    <li>Games: This shows up once you are in at least one game, and this where you play.</li>
                    <li>Your name: This is where you can manage your account (password reset, change your email, etc.).</li>
                </div>
            </React.Fragment>
        )
    };
    // end of Component
}
 
export default AfterLogIn;