import React, { Component } from 'react';
import './invitations.css';
import './../css/bump-buttons.css';
import * as userService from "../../services/userService";
import * as gameService from "../../services/gameService";

class Inbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foundInvitations: [],
            loading: false
        };
      };
    
    async componentDidMount() {
        this.refresh();
    };
    


    // This refreshes the list of invites (by calling the back end) only when
    // here is a new unread inviate that shows up
    componentDidUpdate(prevProps, prevState){
        const invitationCounts = this.props.invitationCounts
        if (invitationCounts.to_unread > prevProps.invitationCounts.to_unread ||
            invitationCounts.to_read !== prevProps.invitationCounts.to_read
        ){
            this.refresh();
        }
        return null
    }
    
    // Change the state of an invitation. They go from unread > read > (accpeted or declined)
    setInvitationState = async (invitationId, state, sessionObj) => {
        try{
            // setInvitationState is an authenticated path, so we need to pass in the sessionObj
            var result = await userService.setInvitationState(invitationId, state, sessionObj);
            this.refresh();
        } 
        catch (error) {
            if ("response" in error){
                    if ("status" in error.response){
                        if (error.response.status === 403) {
                            // pass in false, because we don't want to try to delete
                            // the session from the back end, because we know the 
                            // sessionObj is no good
                            this.props.onUserLogOut(false);
                        };
                        if (error.response.status === 429) {
                            // 429 means the api gateway throttled us
                            // to to: figure out a retry strategy that doesn't
                            // compete with Axios
                            // for now, just wait one secon
                            await this.wait(1000);
                        }
                    }
                };
            return
        }
        return result
    };
    
    invitationSetAcceptDecline = async (invitationId, state) => {
        const sessionObj = this.props.getSessionObj;
        try {
            await this.setInvitationState(invitationId,state,sessionObj)
        }
        catch(error) {
            // this could happen if there is an error but it's not a 403
            console.log("error in setting the invitation state")
            console.log(error)
        }
    };
    
    handleDecline = async (event) => {
        const invitationId=(event.target.name);
        // first thing is to update the button text as soon as the user clicks on it
        // so the site appears responsive
        const declineButtonKey = this.generateDeclineButtonKey(invitationId);
        this.setState({[declineButtonKey]: "Declining..."},
        ()=>{this.invitationSetAcceptDecline(invitationId, "declined")}
        )
    };
    
    handleAccept = async (event) => {
        const invitationId=(event.target.name);
        // first thing is to update the button text as soon as the user clicks on it
        // so the site appears responsive       
        const acceptButtonKey = this.generateAcceptButtonKey(invitationId);
        this.setState({[acceptButtonKey]: "Accepting..."},
        ()=>{this.invitationSetAcceptDecline(invitationId, "accepted");})
        const sessionObj = this.props.getSessionObj;
        try{
            // createGame is an authenticated path, so we need to pass in the sessionObj
            var result = await gameService.createGame(invitationId, sessionObj);
            this.refresh();
        } 
        catch (error) {
            if ("response" in error){
                    if ("status" in error.response){
                        if (error.response.status === 403) {
                            // pass in false, because we don't want to try to delete
                            // the session from the back end, because we know the 
                            // sessionObj is no good
                            this.props.onUserLogOut(false);
                        } 
                    } else{
                        console.log("error in creating the game")
                        console.log(error)
                    }
                };
        }
        
        console.log("the result of handleAccept calling gameService.createGame is");
        console.log(result);
        
    };
    
    generateBlurb = (blurb) => {
        return blurb ? ' "'+blurb+'"' : ''
    }
    
    generateAcceptButtonKey = (invitationId) => {
        return "Accept-" + invitationId
    }
    
    generateDeclineButtonKey = (invitationId) => {
        return "Decline-" + invitationId
    }
    
    // I'm not a fan of this
    // but when there are a lot of invitations moving from unread to read, 
    // I have to slow down the set state change posts somehow
    // so I tie up the thread for bit
    wait = (ms) => {
        const start = Date.now();
        let now = start;
        while (now - start < ms) {
          now = Date.now();
        }
    }
        
    update2Read = async (foundInvitations) => {
        //now that inbox is loaded and rendered, 
        // we can go through it a bit slower and mark things as read
        // this helps avoid throttling when there's a lot being marked as read all at once
        const sessionObj = this.props.getSessionObj;
        // each call to set the invitation state will wait a random amount of time that is
        // more than min and less that max
        const maxWait = 500
        const minWait = 50
        foundInvitations.forEach(async (foundinvitation) => {
            if (foundinvitation.state.S === "unread") {
                this.setInvitationState(foundinvitation.invitationId.S,"read",sessionObj);
                await this.wait(Math.floor(Math.random() * (maxWait - minWait + 1) + minWait));
                this.forceUpdate();
            };
        })
    }
    
    generateRow = (foundinvitation) => {
        const invitationId= foundinvitation.invitationId.S;
        const fromname= foundinvitation.fromname.S;
        const fromblurb= foundinvitation.fromblurb.S;
        const message= foundinvitation.message.S;
        const state= foundinvitation.state.S;
        const now = Math.floor(Date.now())/1000;
        // calculate to one decimal 
        var days2expire= Math.round(((foundinvitation.ttl.N - now) / 60 /60 /24)*10)/10;
        // but if it's greated an 1 day, then just keep it simple and show whole days
        if (days2expire > 1) {days2expire = Math.floor(days2expire)};
        // button text should immediately react when clicked, therefore
        // the button text has to be in the state and rendered to match the state
        // and this generates the entry in state for each button uniquely for each invite
        const acceptButtonKey = this.generateAcceptButtonKey(invitationId);
        const declineButtonKey = this.generateDeclineButtonKey(invitationId);
        this.setState({
            [acceptButtonKey]: "Accept",
            [declineButtonKey]: "Decline"})
        
        const row= (<tr key={invitationId}>
                <td>{fromname+this.generateBlurb(fromblurb)}</td>
                <td>{message}</td>
                <td>{state}</td>
                <td>{days2expire}</td>
                <td>{state==="accepted" ? "Invitation Accepted! Check out Games (at the top) to make the first move."  : <div><button
                        name={invitationId} 
                        className="bumpBtns btn"
                        onClick={this.handleAccept}
                        >{this.state[acceptButtonKey] === undefined ? "Accept": this.state[acceptButtonKey]}</button> 
                        <button 
                        name={invitationId} 
                        className="bumpBtns btn"
                        onClick={this.handleDecline}
                        >{this.state[declineButtonKey] === undefined ? "Decline" : this.state[declineButtonKey] }</button></div>}
                </td>
            </tr>);                
        return row
    };
    
    refresh = async () => {
        // load and render any invitations
        this.setState({
            loading: true,
        });
        const sessionObj = this.props.getSessionObj;
        try {
           var response = await userService.getInvitationsTo(sessionObj);
        }
        catch (error) {
            if ("response" in error){
                    if ("status" in error.response){
                        if (error.response.status === 403) {
                            // pass in false, because we don't want to try to delete
                            // the session from the back end, because we know the 
                            // sessionObj is no good
                            this.props.onUserLogOut(false);
                        } 
                    } else{
                        console.log("error in getInvitationsTo")
                        console.log(error)
                    }
                };
            return;
        };
        const foundInvitations = JSON.parse(response.headers.foundinvitations)
        // this generates the html for each table row and stores the actual html in the state
        // not sure if that's best practice. I suspect it's not, but it works.        
        var invitations = []
        if (foundInvitations.length>0) {
            foundInvitations.forEach(foundinvitation => {
                invitations.push(this.generateRow(foundinvitation));
            });
        this.setState({foundInvitations:invitations, loading:false}, await this.update2Read(foundInvitations))
        } else {
        this.setState({foundInvitations:[], loading:false})    
        };

        // at this point, it's drawn the table and set it in state.
        // now at a slower pace, we mark them as read

    }
     
    render() { 
        return (
            !this.props.getLoggedIn ? "" :
            <React.Fragment>
                <div className= "indent sentInvitations">
                    <div className= "raleway invitationsBox ">
                    <span>Here are the invitations you have received: </span>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>Message</th>
                                    <th>State</th>
                                    <th>Expires in Days</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>{this.state.foundInvitations}</tbody>
                        </table>
                        {this.state.foundInvitations.length===0 && !this.state.loading ? "No invitations found":null}
                        {this.state.loading ? "Loading..." : null}
                    </div>

                </div>
            </React.Fragment>
        )
    };
    // end of Component
}
 
export default Inbox;