
import React, { Component } from 'react';
import GameSquare from './gamesSquare'
import upPurpleCursor from './cursors/upPurple.cur'
import upOrangeCursor from './cursors/upOrange.cur'
import rightPurpleCursor from './cursors/rightPurple.cur'
import rightOrangeCursor from './cursors/rightOrange.cur'
import downPurpleCursor from './cursors/downPurple.cur'
import downOrangeCursor from './cursors/downOrange.cur'
import leftPurpleCursor from './cursors/leftPurple.cur'
import leftOrangeCursor from './cursors/leftOrange.cur'
import xCursor from './cursors/x.cur'


class GamesBoard extends Component {   

    getRow(squares, currentGame, key, onClick){
        return(
            <div id={key} key={key} className="Bumprow">
                {squares.map(square => 
                    <GameSquare
                        key = {square.pos}
                        id={square.pos} 
                        user_id={square.user_id}
                        piece={square.piece}
                        currentGame={currentGame}
                        onClick={onClick}
                        winningSquare = {square.winningSquare}
                        getSessionObj={this.props.getSessionObj}
                    ></GameSquare>
                )
                }
            </div>
        ) 
    };

    getCursor(currentGame){
        // const player = players.find(player => player.turn==="yep");
        // const piece = player.selectedPiece;
        // const name = player.name;
        var piece = null;
        if (currentGame.turn_user_id === currentGame.p1_user_id) {piece = currentGame.p1_selected_piece};
        if (currentGame.turn_user_id === currentGame.p2_user_id) {piece = currentGame.p2_selected_piece};

        switch (piece){
            case "up":
                return currentGame.turn_user_id === currentGame.p1_user_id ? upPurpleCursor : upOrangeCursor;
            case "right":
                return currentGame.turn_user_id === currentGame.p1_user_id? rightPurpleCursor : rightOrangeCursor;
            case "down":
                return currentGame.turn_user_id === currentGame.p1_user_id? downPurpleCursor : downOrangeCursor;
            case "left":
                return currentGame.turn_user_id === currentGame.p1_user_id? leftPurpleCursor : leftOrangeCursor;
            case "x":
                return xCursor;
            default:
                //do nothing. This can happen when rendering a winning board, and therefore, 
                // turn_user_id is 0 in that situation
        }

    };

    
    render() { 
        const currentGame = this.props.currentGame;
        const onClick = this.props.onClick;
        const cursor = this.getCursor(currentGame);

        var boardRectangle = null;
        var boardRectangleLeft = null;
        var boardRectangleTop = null;
        var boardRectangleHeight = null;
        var boardRectangleWidth = null;
    
        // These board only exist when you are actully in a game, therefore:
        const boardElement = document.getElementById("board");
        if (boardElement !==null) {
            boardRectangle = boardElement.getBoundingClientRect();
            boardRectangleLeft = boardRectangle.left;
            boardRectangleTop = boardRectangle.top;
            boardRectangleHeight = boardRectangle.height;
            boardRectangleWidth = boardRectangle.width;
        }

        // each row is 20 squares long
        // so this adds 20 squares at a time to the rows array
        let rows =[]
        for (let y=0;y<20;y++){
            rows.push(this.getRow(currentGame.board.slice(y*20,(y*20)+20), currentGame, y, onClick))
        }

        return (
            <React.Fragment>
            <div id="board" className="board" style={{cursor: "url(" + cursor + "), auto"}}>
                {rows}
            </div> 
            {this.props.boardLoading ? (<span className ="isLoading" style={{left:boardRectangleLeft, top:boardRectangleTop, height:boardRectangleHeight, width:boardRectangleWidth }}>Loading...</span>) : null}
            </React.Fragment>
        // end of return
        );
    // end of render 
    }

}
export default GamesBoard;
