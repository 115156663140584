import Inbox from './../invitations/inbox';
import CreateInvitation from './../invitations/createInvitation';
import SentInvitations from './../invitations/sentInvitations';
import InvitationsOverview from './../invitations/invitationsOverview';
import React, { Component } from 'react';
import { NavLink} from "react-router-dom";

import './nav.css';

class Navigation extends Component {
        constructor(props) {
        super(props);
        this.state = {
            gamesMenuClosed: true,
            invitationsMenuClosed: true,
            redirectNow:false
        };
      };
    
    handleGamesMenuClick = () => {
        this.props.setInvitationsMenuItem("none");
        this.setState({
            gamesMenuClosed:!this.state.gamesMenuClosed,
            invitationsMenuClosed:true
        })
    };

    handleInvitationsMenuClick = () => {
        this.setState({
            invitationsMenuClosed:!this.state.invitationsMenuClosed,
            gamesMenuClosed:true
        })
    };
    
    closeAllMenus = () => {
        this.props.setInvitationsMenuItem("none");
        this.setState({
            gamesMenuClosed:true,
            invitationsMenuClosed:true
        })
    };
    
    // The event has the name of the element that was clicked on, therefore
    // the name attribute of each row in the menu is the game_id
    handleGameMenuItemClick = (event) => {
        // In the router component, there's a function that updates the 
        // currentGame object to whatever game_id is passed in
        const newCurrentGame = event.currentTarget.getAttribute("name");
        this.props.changeCurrentGame(newCurrentGame);
        
    }

    // The event has the name of the element that was clicked on, therefore
    // the name attribute of each row in the menu is the invitationid
    handleInvitationsMenuItemClick = (event) => {
        const itemClicked = event.currentTarget.getAttribute("name");
        // In the router component, there's a function that updates the 
        // invitations menu time to be whatever is cliked on
        // and the bottom of this component renders it
        this.props.setInvitationsMenuItem(itemClicked);
        this.setState({invitationsMenuClosed:true})
        return 
    }
    
    // This creates the actual games listed in the menu (i.e. the clickable things in the menu)
    generateGamesMenuItemRow = (name1, name2, game_id) => {
        const currentGame = this.props.currentGame;
        var currentGameIndicator = "";
        // This marks the current game. It works for now, but its a programmer solution. 
        // I need a more visually interesting solution.
        if (currentGame.game_id === game_id) {currentGameIndicator = " <-- Current Game"};
        const row = (<div 
                        onClick = {this.handleGameMenuItemClick}
                        className="MenuHover" 
                        key={game_id} 
                        name={game_id}> 
                        {name1} vs {name2} {currentGameIndicator}
                        </div>)
        return row
    }
    
    // if the menu is open, then outline the nav item
    gameMenuClasses = () => {
        if (this.state.gamesMenuClosed === false){
            return "underlineWhenHovering nav-item nav-link outline"
        } 
        else {
            return "underlineWhenHovering nav-item nav-link"
        }
    };
    
    // if the menu is open, then outline the nav item
    invitationsMenuClasses = () => {
        if (this.state.invitationsMenuClosed === false){
            return "underlineWhenHovering nav-item nav-link outline"
        } else {
            return "underlineWhenHovering nav-item nav-link"
        }
    };

    render () {
        const sessionObj = this.props.getSessionObj;
        const name = this.props.getUserName;
        const loggedIn = this.props.getLoggedIn;
        const {to_unread} = this.props.invitationCounts;
        const gamesOurTurnCount = this.props.gamesOurTurnCount;
        const games = this.props.games;
        const gamesCount = games.length;
        const gamesOurTurn = [];
        const gamesNotOurTurn = [];
        const gamesMenu = document.getElementById("gamesMenu");
        var gamesMenuLeft = 0;
        var gamesMenuBottom = 0;
        if (gamesMenu !== null) {
            // These coordinates are used to ensure the menu items are always placed directly below
            var gamesMenuRect = gamesMenu.getBoundingClientRect();
            gamesMenuLeft = gamesMenuRect.left;
            gamesMenuBottom = gamesMenuRect.bottom;            
        }
        const invitationsMenu = document.getElementById("invitationsMenu");
        var invitationsMenuLeft = 0;
        var invitationsMenuWidth = 0;
        var invitationsMenuBottom= 0;
        if (invitationsMenu !== null) {
            // These coordinates are used to ensure the menu items are always placed directly below
            var invitationsMenuRect = invitationsMenu.getBoundingClientRect();
            invitationsMenuLeft = invitationsMenuRect.left;
            invitationsMenuBottom = invitationsMenuRect.bottom;
            var invitationsMenuRight = invitationsMenuRect.right;
            invitationsMenuWidth = invitationsMenuRight-invitationsMenuLeft
        }

        var invitation = "";
        to_unread > 0 ? invitation = "Invitations(" + to_unread + ")" : invitation = "Invitations";
        var gameNavText = "";
        gamesOurTurnCount > 0 ? gameNavText = "Game(" + gamesOurTurnCount + ")" : gameNavText = "Game";
        var gamesNavText = "";
        gamesOurTurnCount > 0 ? gamesNavText = "Games(" + gamesOurTurnCount + ")" : gamesNavText = "Games";
        
        var oneGame = null;
        
        if (loggedIn) {oneGame = (
            <React.Fragment>
                <NavLink
                    id="gamesMenu"
                    className={this.gameMenuClasses()}
                    to="/game"
                    onClick={this.handleGamesMenuClick}>
                    {gameNavText}
                </NavLink>
            </React.Fragment>
            )};
            
        var multipleGames = null;
        if (loggedIn) {multipleGames = (
            <React.Fragment>
                <NavLink
                    id="gamesMenu"
                    name="gamesMenu"
                    className={this.gameMenuClasses()}
                    to="/game"
                    onClick={this.handleGamesMenuClick}>
                    {gamesNavText}
                </NavLink>
            </React.Fragment>
            )};
        
        games.forEach(game=>{
            if (game.turn_user_id === sessionObj.user_id) {
                gamesOurTurn.push(this.generateGamesMenuItemRow(game.p1_name,game.p2_name,game.game_id))
            } else {gamesNotOurTurn.push(this.generateGamesMenuItemRow(game.p1_name,game.p2_name,game.game_id))}
        });
            
        const gamesMenuItems = ( 
                // the style ensures this is always placed below the menu item in navigation.
                // It has to be an inline style, as opposed to the css file, because it's caculated in real time.
                <div className="MenuStyles" style={{left:gamesMenuLeft, top:gamesMenuBottom}}>
                    <div ><em>Your Turn:</em></div>
                    <div >{gamesOurTurn.length >0 ? gamesOurTurn : "No games where it's your turn"}</div>
                    <div ><em>Their Turn:</em></div>
                    <div >{gamesNotOurTurn.length >0 ? gamesNotOurTurn : "No games where it's their turn"}</div>
                </div> 
            );
        
        const invitationsMenuItems = (
                // the style ensures this is always placed below the menu item in navigation.
                // It has to be an inline style, as opposed to the css file, because it's caculated in real time.
                <div className="MenuStyles" style={{left:invitationsMenuLeft, top:invitationsMenuBottom, width: invitationsMenuWidth}}>
                    <div name="create" className="MenuHover" onClick={this.handleInvitationsMenuItemClick}>Create </div>
                    <div name="inbox" className="MenuHover" onClick={this.handleInvitationsMenuItemClick}>{to_unread > 0 ? "Inbox("+to_unread+")": "Inbox"} </div>
                    <div name="sent" className="MenuHover" onClick={this.handleInvitationsMenuItemClick}>Sent </div>
                    <div name="overview" className="MenuHover" onClick={this.handleInvitationsMenuItemClick}>Overview </div>
                </div> 
            );
        
        return (
        <React.Fragment>
            {this.state.gamesMenuClosed === false && loggedIn ? gamesMenuItems: null }
            {this.state.invitationsMenuClosed === false && loggedIn ? invitationsMenuItems: null }
            <div className="wrapper">
             <nav className = "navigation navbar navbar-expand navbar-light bg-white" >
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav verticalAlignMiddle" >
                    <img src="../../images/bump-logo.png" alt="logo" className="logo-pic"></img>
                        <NavLink 
                            className="logo-text underlineWhenHovering nav-item nav-link"
                            onClick={this.closeAllMenus}
                            style={{paddingRight:20, paddingTop:'.7vw'}}
                            to="/about">
                            Bump: 
                        </NavLink>
                        { gamesCount <= 1 ? oneGame : multipleGames }
                        <NavLink 
                            id="invitationsMenu"
                            name="invitationsMenu"
                            onClick={this.handleInvitationsMenuClick}
                            className={this.invitationsMenuClasses()}
                            to={loggedIn ? "/invitations": null }>
                            {loggedIn ? invitation : null }
                        </NavLink>
                        <NavLink 
                            onClick={this.closeAllMenus}
                            className="underlineWhenHovering nav-item nav-link" 
                            to="/howto">
                            How To Play
                        </NavLink>
                        <NavLink 
                            onClick={this.closeAllMenus}
                            className="underlineWhenHovering nav-item nav-link" 
                            to={loggedIn ? "/account":"/login" }>
                            {loggedIn ? name: "Login"}
                        </NavLink>
    
                     </div>
                 </div>
             </nav>
             <h1 className="appName">bump</h1> 
             </div>
            {this.props.getInvitationsMenuItem === "inbox" ? ( 
                <Inbox 
                    getSessionObj={this.props.getSessionObj}
                    onUserLogOut = {this.props.onUserLogOut}
                    getLoggedIn = {this.props.getLoggedIn}
                    invitationCounts = {this.props.invitationCounts}
                    />
                    ) : null }
            {this.props.getInvitationsMenuItem === "create" ? 
                <CreateInvitation
                    getSessionObj = {this.props.getSessionObj}
                    onUserLogOut = {this.props.onUserLogOut}
                    getLoggedIn = {this.props.getLoggedIn}
                    getUserObj = {this.props.getUserObj}
                    /> : null}
            {this.props.getInvitationsMenuItem === "sent" ? 
                <SentInvitations
                    getSessionObj = {this.props.getSessionObj}
                    onUserLogOut = {this.props.onUserLogOut}
                    getLoggedIn = {this.props.getLoggedIn}
                    invitationCounts = {this.props.invitationCounts}
                    /> : null}
            {this.props.getInvitationsMenuItem === "overview" ? 
                <InvitationsOverview
                    getLoggedIn = {this.props.getLoggedIn}
                    invitationCounts = {this.props.invitationCounts}
                    /> : null}
        </React.Fragment>
        );
    };
};



export default Navigation;