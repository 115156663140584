import React, { Component } from 'react';
import Joi from "joi-browser";
import * as userService from "../../services/userService";
import './invitations.css';
import './../css/bump-buttons.css';

class CreateInvitation extends Component {
    constructor(props) {
    super(props);
    this.state = {
        search: "",
        toName: "",
        toUser_Id: "",
        foundnames: [],
        message: "",
        days2expire: "14",
        errors: {},
        searchButtonDisable: true,
        searchButtonText: "Search",
        submitButtonDisable: true,
        submitButtonText: "Invite"
    };
    };
    
    handleSubmit = async (e) => {
        const userObj = this.props.getUserObj
        e.preventDefault();
        this.setState({
            submitButtonText: "Sending the invitation...",
            submitButtonDisable: true
        })
        const invite = {
            toname: this.state.toName,
            touser_id: this.state.toUser_Id,
            message: this.state.message,
            fromname: userObj.name,
            fromblurb: userObj.blurb,
            fromuser_id: userObj.user_id,
            days2expire: this.state.days2expire
        }
        const sessionObj = this.props.getSessionObj;
        try {
           var response = await userService.createInvitation(invite, sessionObj);
        }
        catch (error) {
            // createInvitation requires a valid sessionObj, therefore if we get back a
            // 403 from the post to it, that means our sessionObj is no good. 
            // So that means we need to log out 
            if ("response" in error){
                    if ("status" in error.response){
                        if (error.response.status === 403) {
                            // pass in false, because we don't want to try to delete
                            // the session from the back end, because we know the 
                            // sessionObj is no good
                            this.props.onUserLogOut(false);
                        };
                    }
                };
            
            if (error.response.status === 401) {
                this.props.onUserLogOut();
            }
            return
        }

        if ( response.status===200 ) {
            this.setState({
                searchButtonText: "Search",
                submitButtonText: "Invitation Sent",
                submitButtonDisable: true,
                searchButtonDisable: true,
                foundnames: [],
                toName: "",
                toUser_Id: "",
                search: "",
            })            
        }


    
    };
    
    schema = {
        search: Joi.string().required().label("Search:").max(30).min(3),
        message: Joi.string().empty('').optional().label("Message").max(200),
        days2expire: Joi.number().integer().required().label("Expires in days").min(1).max(30)
    };
    
    // The point of this is to have the option to validate each of the inputs
    // independently, without having to validate th entire form
    validateInput = ({ name, value }) => {

        const obj = {[name]: value};
        const schema = {[name]: this.schema[name] };
        var {error} = Joi.validate(obj, schema);
        let msg = "";
        // pull out the error message from the Joi object into a local variable
        if (error) {msg = error.details[0].message};
        return msg ? msg : null;
    };
    
    // figure out the buttons
    buttonsState(){
        if (Object.keys(this.state.errors).length > 0) {
            var errorFree = false
            } else {
            errorFree = true
            };
        // case 1: No errors, and to is populated in
        if (errorFree && this.state.toName.length>0) {

            this.setState({
                searchButtonDisable: false,
                submitButtonText:"Invite",
                submitButtonDisable: false                
            });
        };
            
        // case 2: No errors, but to is not populated
        if (errorFree && this.state.toName.length === 0) {
            this.setState({
                searchButtonDisable: false,
                submitButtonText:"Invite",
                submitButtonDisable: true                
            });
        };
            
        // case 3: errors
        if (!errorFree) {
            this.setState({
                searchButtonDisable: true,
                submitButtonText:"Invite",
                submitButtonDisable: true                
            });
        };
    };

    // query the user table for all users names or blubs containing chars in to
    // and put them in the state
    search = async () => {
        // This button does dual duty. If the toName is populated, 
        // then its a clear button that removes  the toName
        if (this.state.toName.length > 0 ) {
            this.setState({
                toName:"",
                toUser_Id:"", 
                searchButtonText: "Search",
                foundnames: [],
            })
            return
        }
        // this only executes if the toname is not populated
        // which means we need to do a search 
        this.setState({
            searchButtonText:"Searching...",
            toName:"",
            toUser_Id:"",
            foundnames: []
        })
        const searchFor = this.state.search
        // need to get the session obj and pass it in 
        // with the searchFor
        const sessionObj = this.props.getSessionObj;
        try {
            var response = await userService.searchUser(searchFor, sessionObj);
            }
        catch (error) {
            // searchUser requires a valid sessionObj, therefore if we get back a
            // 401 from the post to it, that means our sessionObj is no good. 
            // So that means we need to log out
            if (error.response.status === 401) {
                // In the event of a bad sessionObj, no need to contact the back end
                // to delete it, so pass in false
                try{
                    this.props.onUserLogOut(false);
                }
                catch (error) {
                    // do nothing. I just want to avoid a client side unhandled exception
                }
            }
            return
        }
        const foundnames = JSON.parse(response.headers.foundnames)
        if (foundnames.length===0) {
            this.setState({
                errors: {search:"Could not find any names containing: " + searchFor},
                searchButtonDisable: true
            })};
        this.setState({
            foundnames:foundnames,
            searchButtonText:"Search"
        }, () =>{
            // if there's only one name, just set the To: to that name
            if (foundnames.length===1) {
                this.setState({
                    toName:foundnames[0].name.S,
                    toUser_Id:foundnames[0].user_id.S, 
                    searchButtonText: "Clear"
                }, this.buttonsState)
            };            
        });

    }; 
    
    handleChange = async (e) => {
        const ct = e.currentTarget;
        const errors = {...this.state.errors};
        const errorMessage = await this.validateInput(ct);
        if (errorMessage) {
            errors[ct.name] = errorMessage;
        }
        else {
            delete errors[ct.name];
        };
        this.setState({[ct.name]: ct.value, errors},
        this.buttonsState
        );
    };
    
    selectName = (e) => {
        var ct = e.currentTarget;
        const foundnames = [...this.state.foundnames]
        const selectedName = foundnames.find(foundname => foundname.name.S === ct.name)
        this.setState({
            toName:selectedName.name.S,
            toUser_Id:selectedName.user_id.S,
            searchButtonText: "Clear"
        }, this.buttonsState)
    };
    
    makeButton = (foundName) => {
        const name = foundName.name.S
        var blurb =foundName.blurb.S
        const user_id=foundName.user_id.S
        if (blurb.length>0) {blurb="(" + blurb + ")"} 
        return (
        <button
            key = {name}
            id = {user_id}
            name = {name}
            onClick= {this.selectName}
            className = "baseButton dropdown-item foundNames foundNamesHover"
            type="button"
            >{name} {blurb}</button>)
    };
    
    showFoundNames = () => {
        const foundNames = this.state.foundnames
        var buttons = []
        buttons.push(<li key="listheader" className = 'dropdown-item foundNames'>Choose a name to add to the To:</li>)
        foundNames.forEach(foundName => buttons.push(this.makeButton(foundName)))
        return buttons
    };
    
    render() {
        if (this.props.getLoggedIn) {
            return (
                <React.Fragment>
                <br />
                <div className="raleway">
                <form onSubmit={this.handleSubmit}>
                    <div className="row standardSize align-items-center">
                    <span>Create Invitation: </span>
                        <div className = "col">
                            {this.state.toName.length===0 ? <label htmlFor="search" className="form-label">Search for a name to invite</label> : ""}
                            <div>
                            {this.state.toName.length===0 ? (
                                <input 
                                    
                                    autoFocus 
                                    name="search"
                                    type="text" 
                                    id="search"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    aria-describedby="searchHelp" 
                                    ></input>
                                ) : <label id="toName">To: {this.state.toName}</label> }
                            <button
                                id="searchButton"
                                onClick = {this.search}
                                className = "btn bumpBtns"
                                disabled={this.state.searchButtonDisable}
                                type="button"
                                >{this.state.searchButtonText}</button>
                            </div>
                            {this.state.toName.length===0 ? (<div 
                                id="searchHelp" 
                                style={ this.state.errors.search ? { color:"red", fontWeight:"bold"}: {}}
                                className= "form-text"> 
                                {this.state.errors.search ? this.state.errors.search : "Enter at least three characters of a name. Click search to find them."}
                                </div>) : "" }
                            <div className={this.state.foundnames.length>0 && this.state.toName.length===0 ? "foundbox" : ""}>
                                {this.state.foundnames.length>0 && this.state.toName.length===0 ? this.showFoundNames() : ""}
                            </div>
                        </div>
                    </div>
    
                    <div className="row standardSize">
                        <div className = "col">
                            <label htmlFor="message" className="form-label">Message</label>    
                            <input 
                              
                                name="message"
                                type="text" 
                                id="message"
                                className="form-control"
                                onChange={this.handleChange}
                                aria-describedby="messageHelp" 
                                ></input>
                            <div 
                                id="messageHelp" 
                                style={ this.state.errors.message ? { color:"red", fontWeight:"bold", paddingBottom: '1vw'}: {paddingBottom: '1vw'}}
                                className= "form-text"> 
                                {this.state.errors.message ? this.state.errors.message : "Include a message. 200 characters max. Optional."}
                                </div>
                        </div>
                    </div>
                    
                    <div className="row standardSize">
                        <div className = "col">
                            <label htmlFor="days2expire" className="form-label">Expires in days</label>    
                            <input 
                                name="days2expire"
                                defaultValue="14"
                                type="text"
                                id="days2expire"
                                className="form-control"
                                onChange={this.handleChange}
                                aria-describedby="days2expireHelp" 
                                ></input>
                            <div 
                                id="days2expireHelp" 
                                style={ this.state.errors.days2expire ? { color:"red", fontWeight:"bold", paddingBottom: '1vw'}: {paddingBottom: '1vw'}}
                                className= "form-text"> 
                                {this.state.errors.days2expire ? this.state.errors.days2expire : "Days until this invitation expires. 30 max."}
                                </div>
                        </div>
                    </div>
                    
                    <button
                        id="submit"
                        disabled = {this.state.submitButtonDisable}
                        type="submit" 
                        className="btn bumpBtns"
                        >
                        {this.state.submitButtonText}
                        </button>
    
                </form>
                </div>
                </React.Fragment>
                
            )
        }
    };
    // end of Component
}
 
export default CreateInvitation;