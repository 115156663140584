import React, { Component } from 'react';
import GamesBoard from './gamesBoard';
import GamesHeader from './gamesHeader';
import * as gameService from "../../services/gameService";

class Games extends Component {
  
  // I'm not a fan of this, but if we are getting throttled, 
  // I need the client side to wait. So I tie up the thread
  wait = (ms) => {
        const start = Date.now();
        let now = start;
        while (now - start < ms) {
          now = Date.now();
        }
  };

  // Handles when you select a different piece to play
  handleSelectPiece = async selectedPiece => {
    this.props.setBoardHeaderLoading(true);
    const currentGame = this.props.currentGame;
    const sessionObj = this.props.getSessionObj;
    if (currentGame.all_players.includes(sessionObj.user_id)){
      try{
        // gamesSelectPiece is an authenticated path, so we need to pass in the sessionObj
            var result = await gameService.gamesSelectPiece(selectedPiece,currentGame.game_id, sessionObj);
          } 
      catch (error) {
          console.log(error);
          if ("response" in error){
                  if ("status" in error.response){
                      if (error.response.status === 403) {
                          // pass in false, because we don't want to try to delete
                          // the session from the back end, because we know the 
                          // sessionObj is no good
                          this.props.onUserLogOut(false);
                      };
                      if (error.response.status === 429) {
                          // 429 means the api gateway throttled us
                          // to do: figure out a retry strategy that doesn't suck
                          // for now, just wait one second
                          await this.wait(1000);
                      }
                  }
              };
          return
          }
  }
        else {
          // if the logged user is not a player in the game, something is wrong. Log out.
          this.props.onUserLogOut();
        }

    //console.log(result.headers.message);    
  };
  
  // Handles when you click on a sqaure
  handleSquareClick = async (pos) => {
    this.props.setBoardLoading(true);
    const currentGame = this.props.currentGame;
    const sessionObj = this.props.getSessionObj;
    if (currentGame.all_players.includes(sessionObj.user_id)){
      try{
        // gamesHandleClick is an authenticated path, so we need to pass in the sessionObj
          var result = await gameService.gamesSquareClick(pos,currentGame.game_id, sessionObj);
          } 
      catch (error) {
          console.log(error);
          if ("response" in error){
                  if ("status" in error.response){
                      if (error.response.status === 403) {
                          // pass in false, because we don't want to try to delete
                          // the session from the back end, because we know the 
                          // sessionObj is no good
                          this.props.onUserLogOut(false);
                      };
                      if (error.response.status === 429) {
                          // 429 means the api gateway throttled us
                          // to do: figure out a retry strategy that doesn't suck
                          // for now, just wait one second
                          await this.wait(1000);
                      }
                  }
              };
          return
          }
  }
        else {
          // if the logged user is not a player in the game, something is wrong. Log out.
          this.props.onUserLogOut();
        }

    //console.log(result.headers.message);
  }; 

  render() {
    // if the logged on user is not a part of any games, then don't render the board. Just get out of here.
    if (Object.keys(this.props.currentGame).length === 0) {
            return
        };
    return (
      
      <div className="game">
        <GamesHeader 
          onSelectPiece={this.handleSelectPiece} 
          currentGame={this.props.currentGame}
          getSessionObj={this.props.getSessionObj}
          boardHeaderLoading = {this.props.boardHeaderLoading} 
          ></GamesHeader>
        <GamesBoard
          currentGame={this.props.currentGame}
          onClick={this.handleSquareClick}
          getSessionObj={this.props.getSessionObj}
          boardLoading={this.props.boardLoading}
          >
        </GamesBoard>
      </div>
    );
  }; // end of render method
   
}; // end of class app

export default Games
