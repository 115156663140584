import React, { Component } from 'react';
import './../css/bump.css';
import './../css/pieces.css';

class NotFound extends Component {   

    render() { 

        return (
            
            <div className="wrapper">
            
                <h2>Getting Started:</h2>
                <table>
                <tbody>
                <tr>
                <td>
                <div className='helptext gettingstarted'>
                1) Log in 
                </div>
                <div className='helptext'>
                <em>If you don't have an account yet, create one using the "Login" menu.</em>
                </div>
                </td>
                <td>
                <div className='helptext gettingstarted'>
                2) Invite 
                </div>
                <div className='helptext '>
                <em>Use the "Invitations" menu to find an opponent to play against (shows up after you've logged in).</em>
                </div>
                </td>
                <td>
                <div className='helptext gettingstarted'>
                3) Play 
                </div>
                <div className='helptext'>
                <em>Use the "Game" menu (shows up after you've logged in). </em>
                </div>
                </td>
                </tr>
                </tbody>
                </table>
                <br/>
                <h2>How to play:</h2>
                <div className='helptext'>
                You can add or remove pieces on squares with a light green background color.
                </div>
                <br/>
                <h2>Adding a piece:</h2>
                
                <span className="commands" > <span>select what to play: </span>
                <div 
                    id="left"
                    style={{rotate: 135}}
                    className="arrow left purple notselected" 
                ></div>
                <div 
                    id="right"
                    style={{rotate: -45}}
                    className="arrow right purple selected"  
                ></div>
                <div 
                    id="up"
                    style={{rotate: -135}}
                    className="arrow up purple notselected"
                ></div>
                <div 
                    id="down"
                    style={{rotate: 45}}
                    className="arrow down purple notselected"
                    ></div>
                </span>
                <div className='helptext'>
                Use the direction selectors (pictured above) to choose the direction of the piece you are going to add.
                You play by putting your piece on top of any unblocked piece on the board. 
                You can tell which pieces are not blocked 
                because they have a light green background color. The bottom piece will bump out of the way in the direction it's facing. 
                </div>
                <div className='helptext'>
                The four middle squares are different, and you can play on them when they are empty. 
                </div>
                <br/>
                <h2>Removing a piece:</h2>
                <span className = "commands"> <span> remove: </span>
                <span
                id="x"
                >
                <img 
                  className="xHeader notselected"  
                  alt = "Delete selector"
                  src = "../images/x.svg"
                  ></img>
                 5 
                </span>
                </span>
                <div className='helptext'>
                To remove a piece, select the red X (pictured above).
                </div>
                <div className='helptext'>
                Only five pieces can be removed per game in total by both players combined.
                The number after the red X counts down the number remaining pieces that could be removed.
                You can only remove blocked pieces, which are pieces that can't bump because another piece is in their way.
                </div>
                <br/>
                <h2>Winning:</h2>
                <div className='helptext'>
                The first player to get five in a row wins. 
                </div>
                <div className='helptext'>
                They don't have to face the same way. 
                </div>
                <div className='helptext'>
                They can't be diagonal.
                </div>
            </div>
        )
    };
    // end of Component
}
 
export default NotFound