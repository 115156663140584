import http from "./httpService"
import config from "../config.json"
const apiURL=config.apiURL;

// This module contains all the code for interacting with the 
// backend API regarding users

const apiUserCreateURL = apiURL + "/bump-create-user";
const apiSignupTokenURL = apiURL + "/bump-generate-signup-token";
const apiUserLoginURL = apiURL + "/bump-login-user";
const apiUserLogoffURL = apiURL + "/bump-logoff-user";
const apiUserSearchURL = apiURL + "/bump-search-users";
const apiInvitationCreateURL = apiURL + "/bump-create-invitation";
const apiInvitationsSentURL = apiURL + "/bump-get-invitations-from";
const apiInvitationsReceivedURL = apiURL + "/bump-get-invitations-to";
const apiInvitationsSetStateURL = apiURL + "/bump-set-invitation-state";
const apiInvitationsCountURL = apiURL + "/bump-get-invitation-counts";

export function createUser(account, token) {
    const customConfig = {
        headers: {
        'Content-Type': 'application/json'
        }
    };

    // this returns a promise
    return http.post(apiUserCreateURL, {
        account: account, 
        token: token
    }, customConfig)
};

export function getSignupToken() {
    // this returns a promise
    return http.post(apiSignupTokenURL, {})
};

export function loginUser(account, token){
    const customConfig = {
        headers: {
        'Content-Type': 'application/json'
        }
    };
    // this returns a promise
    return http.post(apiUserLoginURL, {
        account: account, 
        token: token
    }, customConfig)
};

export function validateSignupToken(expiration){
        const errmsg = "The form has expired. Refresh and try again."
        let msg = "";
        const now = new Date();
        const expired = new Date(expiration);
        const diff = expired - now;
        diff < 0 ? msg = errmsg: msg = "";
        return msg;
    };
    
export function logoffUser(userObj, sessionObj){
    const customConfig = {
        headers: {
        'Content-Type': 'application/json'
        }
    };
    // this returns a promise
    return http.post(apiUserLogoffURL, {
        userObj: userObj, 
        sessionObj: sessionObj
    }, customConfig)
};

export function searchUser(searchFor, sessionobj){
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        'sessionObj': JSON.stringify(sessionobj)
        }
    };
    // this returns a promise
        return http.post(apiUserSearchURL, {
            searchFor: searchFor
        }, customConfig)
};

export function createInvitation(invite, sessionObj){
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        'sessionObj': JSON.stringify(sessionObj)
        }
    };
    // this returns a promise
        return http.post(apiInvitationCreateURL, {
            invite: invite
        }, customConfig)
};

export function getInvitationsFrom( sessionObj){
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        'sessionObj': JSON.stringify(sessionObj)
        }
    };
    // this returns a promise
        return http.post(apiInvitationsSentURL, {
        //body goes here
        }, customConfig)
};

export function getInvitationsTo(sessionObj){
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        'sessionObj': JSON.stringify(sessionObj)
        }
    };
    // this returns a promise
        return http.post(apiInvitationsReceivedURL, {
        //body goes here
        }, customConfig)
};

export function setInvitationState(invitationid,state, sessionObj){
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        'sessionobj': JSON.stringify(sessionObj),
        'invitationid': invitationid,
        'state':state
        }
    };
    // this returns a promise
        return http.post(apiInvitationsSetStateURL, {
        //body goes here
        }, customConfig)
};

export function getInvitationCounts(sessionObj){
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        'sessionobj': JSON.stringify(sessionObj),
        }
    };
    // this returns a promise
        return http.post(apiInvitationsCountURL, {
        //body goes here
        }, customConfig)
};
