import React, { Component } from 'react';
import './../css/bump.css';

class loggedOut extends Component {   
    render() { 
        return (
            <React.Fragment>
            <div className="wrapper">

                <br/>
                You have been logged out

            </div>
            </React.Fragment>
        )
    };
    // end of Component
}
 
export default loggedOut;