import React, { Component } from 'react';
import './../css/bump.css';

class Loading extends Component {   
    render() { 
        return (
            <div className="navigation wrapper">
            Loading...
            </div>
        )
    };
    // end of Component
}
 
export default Loading;