import React, { Component } from 'react';
import './../css/bump.css';
import './../css/pieces.css';

class About extends Component {   

    render() { 

        return (
            
            <div className="wrapper">
            
                
                <h2>About:</h2>
                <div className='helptext'>
                bump was created entirely by Jay Garing. Please enjoy the game, but be aware that it's in a minimun playable state right now. Feedback and suggestions are welcome: gutsy.peek.0w@icloud.com
                </div>
                <br/>
                <h2>To Do List (in no particular order):</h2>
                <div className='helptext'>
                    <li> Account management features (e.g change your password, etc)</li>
                    <li> Animations / infographics / less "wall of text" </li>
                    <li> 2FA </li>
                    <li> Email notifications (So you know when the other guy makes a move) </li>
                    <li> One player version with AI opponent </li>
                    <li> Detect stalemates </li>
                    <li> History: Moves, Games, Invites, etc. </li>
                    <li> Analytics: Leaderboards, etc. </li>
                    <li> Game chat </li>
                    <li> Watch someone else's game? </li>
                    <li> Improve cold start responsiveness (e.g. try new Lambda snapstart feature) </li>
                    <li> Replace client side polling with pub sub solution (probably AWS AppSync)</li>
                    <li> Add pagination to invitations tabs </li>
                    <li> Refactor the API Gateway with GraphQL? (probably AWS AppSync)</li>
                    <li> Maybe add client side session persistence? (In this age of password managers, does anyone care that they have to log in each time?) </li>
                    <li> Maybe different playing boards? </li>
                    <li> Multidplayer game? Would that get confusing? </li>
                
                </div>
            </div>
        )
    };
}
 
export default About