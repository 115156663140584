import React, { Component } from 'react';
import './invitations.css';
import './../css/bump-buttons.css';
import * as userService from "../../services/userService";

class SentInvitations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foundInvitations: [],
            loading:false
        };
      };
    
    componentDidMount() {
        this.refresh();
    };
    
    componentDidUpdate(prevProps, prevState){
        const invitationCounts = this.props.invitationCounts
        if (invitationCounts.from_unread !== prevProps.invitationCounts.from_unread ||
            invitationCounts.from_read !== prevProps.invitationCounts.from_read ||
            invitationCounts.from_declined !== prevProps.invitationCounts.from_declined ||
            invitationCounts.from_accepted !== prevProps.invitationCounts.from_accepted
            ){
            this.refresh();
        }
        return null
    }
    
    
    generateRow = (toname, state, days2expire, stateLastChanged) => {
        const row = (<tr key={toname + stateLastChanged}>
                        <td>{toname}</td>
                        <td>{state}</td>
                        <td>{days2expire}</td>
                        <td>{stateLastChanged}</td>
                    </tr>);
        return row;
    };

    refresh = async () => {
        this.setState({
            loading:true
        });
        const sessionObj = this.props.getSessionObj;
        try {
           var response = await userService.getInvitationsFrom(sessionObj);
        }
        catch (error) {
            // sentInvitations requires a valid sessionObj, therefore if we get back a
            // 401 from the post to it, that means our sessionObj is no good. 
            // So that means we need to log out 
            if (error.response.status === 401) {
                this.props.onUserLogOut();
            }
            return
        }
        const foundInvitations = JSON.parse(response.headers.foundinvitations)
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        var invitations = []
        if (foundInvitations.length>0) {
            foundInvitations.forEach(foundinvitation => {
                var stateLastChanged = new Date(foundinvitation.stateLastChanged.N * 1000).toLocaleString();
                const now = Math.floor(Date.now())/1000
                // calculate to one decimal 
                var days2expire= Math.round(((foundinvitation.ttl.N - now) / 60 /60 /24)*10)/10;
                // but if it's greated an 1 day, then just keep it simple and show whole days
                if (days2expire > 1) {days2expire = Math.floor(days2expire)};
                invitations.push(this.generateRow(foundinvitation.toname.S,foundinvitation.state.S, days2expire, stateLastChanged + " (" + timeZone + ")"))
            });
        this.setState({foundInvitations:invitations,loading:false})
        } else {
        this.setState({foundInvitations:[],loading:false})    
        };
    }
    
    render() { 
        if(this.props.getLoggedIn){
            return (
                <React.Fragment>
    
                    <div className= "indent sentInvitations">
                        <div className= "raleway invitationsBox">
                        <span>Here are invitations you have sent: </span>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>To</th>
                                        <th>State</th>
                                        <th>Expires in Days</th>
                                        <th>Last Changed</th>
                                    </tr>
                                </thead>
                                <tbody>{this.state.foundInvitations}</tbody>
                            </table>
                             {this.state.loading?"Loading...":null}
                             {this.state.foundInvitations.length===0 && !this.state.loading ? "No invitations found":null}
                        </div>
                </div>
    
                </React.Fragment>
            )
        }
    };
    // end of Component
}
 
export default SentInvitations;