import http from "./httpService"
import config from "../config.json"
const apiURL=config.apiURL;

// This module contains all the code for interacting with the 
// backend API regarding games

const apiGameCreateURL = apiURL + "/bump-create-game";
const apiGetGamesURL = apiURL + "/bump-get-games";
const apiGamesSquareClickURL = apiURL + "/bump-game-square-click";
const apiGamesSelectPieceURL = apiURL + "/bump-game-select-piece";

export function createGame(invitationId, sessionObj) {
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        'sessionobj': JSON.stringify(sessionObj),
        }
    };

    // this returns a promise
    return http.post(apiGameCreateURL, {
        invitationId: invitationId, 
    }, customConfig)
};

export function getGames(sessionObj) {
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        // 'accept-encoding': 'gzip',
        'sessionobj': JSON.stringify(sessionObj),
        }
    };

    // this returns a promise
    return http.post(apiGetGamesURL, {
    }, customConfig)
};

export function gamesSquareClick(pos,game_id, sessionObj) {
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        'sessionobj': JSON.stringify(sessionObj),
        }
    };

    // this returns a promise
    return http.post(apiGamesSquareClickURL, {
        pos: pos, 
        game_id: game_id
    }, customConfig)
};

export function gamesSelectPiece(piece,game_id, sessionObj) {
    const customConfig = {
        headers: {
        'Content-Type': 'application/json',
        'sessionobj': JSON.stringify(sessionObj),
        }
    };

    // this returns a promise
    return http.post(apiGamesSelectPieceURL, {
        piece: piece, 
        game_id: game_id
    }, customConfig)
};