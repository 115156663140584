import React, { Component } from 'react';
import { Navigate } from "react-router-dom";

import './../css/bump-buttons.css';

class Account extends Component {   
    state = {
        buttonDisable: false,
        buttonText: "Log Out",
    };
    
    handleClick = () => {
        this.setState({
            buttonText: "You are logged out",
            buttonDisable: true
        })
        this.props.onUserLogOut();
    };
    
    render() { 
        return (
            <div className="wrapper">


                <br/>
                to do: Need account management stuff here
                <br/>

            <br/>
            <button 
                type="button"
                className="btn bumpBtns"
                disabled = {this.state.buttonDisable}
                onClick={this.handleClick}>{this.state.buttonText}</button>            
            {this.props.loggedIn ? "" : <Navigate to="/loggedOut" />  }
            </div>
        )
    };
    // end of Component
}
 
export default Account;