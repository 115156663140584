import React, {Component} from 'react';
import Joi from "joi-browser";
import { Link } from 'react-router-dom'
import * as userService from "../../services/userService";
import Loading from "./loading";
import AfterLogIn from "./afterLogIn";
import './../css/bump.css';
import './../css/bump-buttons.css';


class LoginForm extends Component {
    state = {
        account: {
            email: "",
            password: ""
        },
        errors: {},
        signup_token: {token: "loading"},
        buttonText: "Enter your email and password",
        buttonDisable: true,
        showPswd: false
    };
    
    componentDidMount = async ()=> {
        // technically I should rename all this to be a "form expiration token",
        // but it's not worth it
        const { headers } = await userService.getSignupToken();
        const signup_token = {"token": headers.signup_token, "expiration_date": headers.expiration_date};
        await this.setState({signup_token}); 
    };

    schema = {
        email: Joi.string().email({ minDomainAtoms: 2 }).required().label("Email"),
        password: Joi.string().required().label("Password").min(8).max(20)
    };

     validate = async () => {    
        const options = {abortEarly: false}
        const { joiError } = Joi.validate(this.state.account, this.schema, options);
        const expiration = this.state.signup_token.expiration_date;
        if (expiration === undefined || expiration === "" ) {
            return {
                errors:{
                    "password": "This form has expired. Please refresh and try again."
                    } 
                };
        }
        const msg = await userService.validateSignupToken(expiration); 

        // Make sure the signup token expiration is still valid
        if (msg) {
            return {
                errors:{
                    "email": msg,
                    "password": msg
                    } 
                };
        };
        if (!joiError) {return null}
        else {
            let errors = {};
            for (let item of joiError.details)
                errors[item.path[0]] = item.message;
            return errors;            
        };
    };
 

    handleSubmit = async e => {
        e.preventDefault();

        const errors = await this.validate();
        this.setState({ errors: errors || {} });

        if (errors) {
            console.log("Not submitted because:");
            console.log(errors);
            return
        };
        this.setState({buttonText:"Logging you in..."});
        let account={...this.state.account};
        let token={...this.state.signup_token};
        try {
          var response = await userService.loginUser(account, token);
        }
        catch (error){
            // if an error comes back, the account was not logged in. Therefore, 
            // show the error on the appropriate input and give it the focus
            
            // to do: This code assumes the error object has these keys, but that's not always true 
            // check if it's a 500 error
            console.log("There was an error...");
            console.log(await error);
            const field = await error.response.headers.field;
            const msg = await error.response.headers.msg;
            console.log(field)
            
            this.setState({
                errors: {[field]:msg}, 
                buttonText: "Enter your email and password",
                buttonDisable: true});
            if (field === "email" || field === "password") {document.getElementById([field]).focus();}
            return;
            
        };
        //user is logged in, so removing token from state
        this.setState({
            buttonText: response.headers.msg,
            signup_token: {expiration_date:"", token:""},
            buttonDisable: true,
            account: {email: "", password: ""}
        });
    
        this.props.onUserObjUpdate( JSON.parse(response.headers.userobj));
        this.props.onSessionObjUpdate( JSON.parse(response.headers.sessionobj));

    };
 
    validateProperty = ({ name, value }) => {
        const obj = {[name]: value};
        const schema = {[name]: this.schema[name] };
        const {error} = Joi.validate(obj, schema);
        //return error ? error.details[0].message : null;
        // pull out the error message from the Joi object into a local variable
        let msg = "";
        if (error) {msg = error.details[0].message};
        const expiration = this.state.signup_token.expiration_date;
        if (!msg) { msg = userService.validateSignupToken(expiration)};
        return msg ? msg : null;
    };
    
    buttonDisable(){
        const errors = {...this.state.errors};
        const email = this.state.account.email;
        const password = this.state.account.password;
        let disable = false;
        // If there are any errors, disable the button
        if (Object.keys(errors).length > 0) {disable = true};
        // If either feild is empty, disable the button
        if (email.length === 0 || password.length === 0 ) {disable =  true};
        // If there is no signup token, disable the button
        if (this.state.signup_token === {}) {disable = true};
        if (disable === true) {
            this.setState({buttonText:"Enter your email and password"})
        } else {
            this.setState({buttonText:"Login"})
        };
        return disable
    };

    handleChange = e => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(e.currentTarget);
        if (errorMessage) errors[e.currentTarget.name] = errorMessage;
        else delete errors[e.currentTarget.name];

        const account = {...this.state.account};
        account[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ account, errors}, ()=>{
            this.setState({buttonDisable: this.buttonDisable()});
        });
    };
    
    toggleShowPassword = () => {
        this.setState({"showPswd": !this.state.showPswd});
    };

    
    render(){
        const { account } = this.state;
        if (this.state.signup_token.token === "loading"){
            return (<Loading />)
        };
        if (this.props.loggedIn) {
            return (
                <React.Fragment>
                    <AfterLogIn/>
                </React.Fragment>
            )
        }; 
        
        
        return (
        <div className="wrapper navigation">
            <form onSubmit={this.handleSubmit}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="mb-3">
                                <label htmlFor="Email" className="form-label">Email address</label>
                                <input 
                                    name="email"
                                    value={account.email}
                                    onChange={this.handleChange} 
                                    autoFocus 
                                    type="email" 
                                    className="form-control" 
                                    id="email" 
                                    aria-describedby="emailHelp" 
                                    autoComplete="username"
                                    ></input>
                                <div 
                                    id="emailHelp" 
                                    style={ this.state.errors.email ? { color:"red", fontWeight:"bold"}: {}}
                                    className= "form-text"> 
                                    {this.state.errors.email ? this.state.errors.email : "We'll never share your email with anyone else"}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-end mb-3">
                        <div className="col-11">
                            <label htmlFor="Password" className="form-label">Password</label>
                            <input 
                                name="password"
                                value={account.password}
                                onChange={this.handleChange} 
                                type= {this.state.showPswd ? "text" :"password"}
                                className="form-control display:inline-block" 
                                id="password" 
                                aria-describedby="passwordHelp" 
                                autoComplete="current-password"
                                ></input>
                        </div>
                        <div className="col-1" style={{paddingLeft: 0}}>
                            <button 
                                type="button"
                                className = "btn btn-light"
                                onClick = {this.toggleShowPassword}
                                >
                                <img 
                                    src= {this.state.showPswd ? "../images/eye-crossed.png" : "../images/eye.png"}
                                    alt= "icon that shows/hides the password"
                                    ></img>
                                </button>
                        </div>
                        <div 
                            id="passwordHelp" 
                            style={ this.state.errors.password ? { color:"red", fontWeight:"bold"}: {}}
                            className="form-text">
                            {this.state.errors.password ? this.state.errors.password : "8-20 characters long"}
                            </div>
                    </div>
                    <button 
                        disabled = {this.state.buttonDisable}
                        type="submit" 
                        className="btn bumpBtns"
                        >
                        {this.state.buttonText}
                        </button>
                    <div id = "signup" className = "row">
                        <label> Don't have an account? </label>
                        <br />
                        <Link to="/signup">Create one Now</Link>

                    </div>
                </div>
            </form>

        </div>
        )
    
}
} 
export default LoginForm;