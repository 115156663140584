import axios from "axios";

// Okay, the point of this is to abstact away the axios module from the
// rest of the code base. If there's a need to use a different http transport library
// this is the only place that needs to be changed


// use this when there's a need to add a header to all outbound requests
// axios.interceptors.request.use( config =>  {
//     return config;
//   },  error => {
//     return Promise.reject(error);
//   });
 
axios.interceptors.response.use(null, error => {
    
    // This resolves to true for false
    const expectedError = 
        error.response && 
        error.response.status >= 
        400 && error.response.status < 500;
    // if there's an error that's not in the 400's, then log it    
    if (!expectedError) {
        // console.log("There was an unexpected error (i.e. not a client error)")
        // console.log(error);
    }

    // Otherwise don't log it and just return the error
    return Promise.reject(error);
}
);

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
};