import React, {Component} from 'react';
import Joi from "joi-browser";
import Loading from "./loading";
import AfterLogIn from "./afterLogIn";
import * as userService from "../../services/userService";
import './../css/bump.css';
import './../css/bump-buttons.css';
import config from "../../config.json"

class SignUp extends Component {
    state = {
        account: {
            name: "",
            blurb: "",
            email: "",
            password: ""
        },
        errors: {},
        signup_token: {token: "loading"},
        message: "",
        buttonText: "Create Account",
        buttonDisable: true,
        showPswd: false
    };

    componentDidMount = async ()=> {
        // Every time you refresh the page, you get a new sign up token that is good for two hours
        const { headers } = await userService.getSignupToken();
        const signup_token = {"token": headers.signup_token, "expiration_date": headers.expiration_date};
        this.setState({signup_token});
    };

    schema = {
        name: Joi.string().required().label("Name").max(30).min(3),
        blurb: Joi.string().empty('').optional().label("Blurb").max(100),
        email: Joi.string().email({ minDomainAtoms: 2 }).required().label("Email").max(254),
        password: Joi.string().required().label("Password").min(8).max(20)
    };

    // This validates the entire form before submission
    // returns truthy if there's a problem and falsy if not
    validate = async () => {    
        const options = {abortEarly: false}
        const { joiError } = Joi.validate(this.state.account, this.schema, options);
        const expiration = this.state.signup_token.expiration_date;
        const msg = await userService.validateSignupToken(expiration); 

        // Make sure the signup token is still valid
        if (msg) {
            return {
                errors:{
                    "password": msg
                    } 
                };
        };
        if (!joiError) {return null}
        else {
            let errors = {};
            for (let item of joiError.details)
                errors[item.path[0]] = item.message;
            return errors;            
        };
    };
    
    handleSubmit = async e => {
        e.preventDefault();
        let errors = this.validate();
        this.setState({ errors: errors || {} });

        // all error checks passed at this point
        this.setState({buttonText:"Creating your account... "})
        let account={...this.state.account};
        let token={...this.state.signup_token};
        try {
            await userService.createUser(account, token);
        }
        catch (error){
            // if an error comes back, the account was not created. Therefore, 
            // show the error on the appropriate input and give it the focus
            // todo: This code assumes error object has these headers, but that's
            // not always true
            const field = error.response.headers.field;
            const message = error.response.headers.msg;

            this.setState({
                errors: {[field]:message}, 
                buttonText: "Create Account",
                buttonDisable: true});
            if (field === "name" || field === "email" || field === "blurb") {document.getElementById([field]).focus();}
            return;
        }
        // Now that account is created, log in the user
        
        this.setState({buttonText:"Logging you in..."});
        try {
          var luResponse = await userService.loginUser(account, token);
        }
        catch (error){
            // if an error comes back, the account was not logged in. Therefore, 
            // show the error on the appropriate input and give it the focus
            
            // to do: This code assumes the error object has these keys, but that's not always true 
            // check if it's a 500 error
            console.log("There was an error...");
            console.log(await error);
            const field = await error.response.headers.field;
            const msg = await error.response.headers.msg;
            console.log(field)
            
            this.setState({
                errors: {[field]:msg}, 
                buttonText: "Create Account",
                buttonDisable: true});
            if (field === "email" || field === "password") {document.getElementById([field]).focus();}
            return;
            
        };
        //user is logged in, so removing token from state
        this.setState({
            buttonText: luResponse.headers.msg,
            signup_token: {expiration_date:"", token:""},
            buttonDisable: true,
            account: {email: "", password: ""}
        });
    
        this.props.onUserObjUpdate( JSON.parse(luResponse.headers.userobj));
        this.props.onSessionObjUpdate( JSON.parse(luResponse.headers.sessionobj));
    };

    // The point of this is to have the option to validate each of the inputs
    // independently, without having to validate th entire form
    validateInput = ({ name, value }) => {

        const obj = {[name]: value};
        const schema = {[name]: this.schema[name] };
        var {error} = Joi.validate(obj, schema);
        let msg = "";
        // pull out the error message from the Joi object into a local variable
        if (error) {msg = error.details[0].message};
        // At this point, if there's no error, that means
        // the Joi validates are good. This next bit
        // adds in a custom check against a local dictionary of 
        // bad passwords. 
        if (!msg && name === "password") { msg = this.validateBadPswd(value) };
        // And finally, if there are no other errors, make sure the 
        // signup token is still valid
        const expiration = this.state.signup_token.expiration_date;
        if (!msg) { msg = userService.validateSignupToken(expiration)};
        return msg ? msg : null;
    };

    allCharactersSame = (pswd) => {
        let n = pswd.length;
        for (let i = 1; i < n; i++)
            if (pswd[i] !== pswd[0])
                return false;
        return true;
    };

    validateBadPswd(pswd) {
        let msg = "";
        const badPasswords = [...config.badPasswords, this.state.account.name, this.state.account.email]
        badPasswords.includes(pswd) || this.allCharactersSame(pswd) || pswd.includes("password") ? msg = "Really?": msg = "" ;
        return msg;
    };

    // if there are no errors, and the inputs are not empty, then enable the button
    buttonDisable(){
        const errors = {...this.state.errors};
        const name = this.state.account.name;
        const email = this.state.account.email;
        const password = this.state.account.password;
        // it's okay if the blurb is empty
        let disable = false;
        if (Object.keys(errors).length > 0) {disable = true};
        if (name.length === 0 || email.length === 0 || password.length === 0 ) {disable =  true};
        if (disable === true) {
            this.setState({buttonText:"Create Account",message:""})
        };
        return disable
    };

    handleChange = async e => {
        // the message is only for the end, so if you are still changing stuff,
        // blank it out 
        this.setState({message:""});
        const errors = {...this.state.errors};
        const errorMessage = this.validateInput(e.currentTarget);
        if (errorMessage) {
            errors[e.currentTarget.name] = errorMessage;
        }
        else {
            delete errors[e.currentTarget.name];
        };

        const account = {...this.state.account};
        account[e.currentTarget.name] = e.currentTarget.value;
        // set the errors first, and THEN figure out the state of the button as a call back
        this.setState({ account, errors}, ()=>{
            this.setState({buttonDisable: this.buttonDisable()});
        });
        
    };
    
    toggleShowPassword = () => {
        this.setState({"showPswd": !this.state.showPswd});
    };

    render(){
        const { account } = this.state;
        if (this.state.signup_token.token === "loading"){
            return (<Loading />)
        };
        if (this.props.loggedIn) {
            return (
                <React.Fragment>
                    <AfterLogIn/>
                </React.Fragment>
            )
        }; 
        
        return (
        <div className="wrapper navigation">
            <h2>Create an account:</h2>
            <br/>
            <form onSubmit={this.handleSubmit}>
            <div className="container">
                
                <div className="row">
                    <div className="col">
                        <div className="mb-3">
                            <label htmlFor="Name" className="form-label">Name</label>
                            <input 
                                autoFocus 
                                name="name"
                                value={account.name}
                                onChange={this.handleChange}  
                                type="text" 
                                className="form-control" 
                                id="name" 
                                aria-describedby="nameHelp" 
                                autoComplete="name"
                                ></input>
                            <div 
                                id="nameHelp" 
                                style={ this.state.errors.name ? { color:"red", fontWeight:"bold"}: {}}
                                className= "form-text"> 
                                {this.state.errors.name ? this.state.errors.name : "How would you like to be known?"}</div>
                        </div>
                    </div>    
                </div>
                
                <div className="row mb-3">
                    <div className="col">
                        <label htmlFor="blurb" className="form-label">Identifying Blurb</label>
                        <input 
                            name="blurb"
                            value={account.blurb}
                            onChange={this.handleChange} 
                            type="text"
                            className="form-control" 
                            id="blurb" 
                            aria-describedby="blurbHelp" 
                            ></input>
                        <div 
                            id="blurbHelp" 
                            style={ this.state.errors.blurb ? { color:"red", fontWeight:"bold"}: {}}
                            className= "form-text"> 
                            {this.state.errors.blurb ? this.state.errors.blurb : "Something about you to help people invite the correct person to a game. Optional. 100 characters max."}</div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col">
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email address</label>
                            <input 
                                name="email"
                                value={account.email}
                                onChange={this.handleChange}  
                                type="email" 
                                className="form-control" 
                                id="email" 
                                aria-describedby="emailHelp" 
                                autoComplete="username"
                                ></input>
                            <div 
                                id="emailHelp" 
                                style={ this.state.errors.email ? { color:"red", fontWeight:"bold"}: {}}
                                className= "form-text"> 
                                {this.state.errors.email ? this.state.errors.email : "We'll never share your email with anyone else"}</div>
                        </div>
                    </div>
                </div>
                
                <div className="row align-items-end mb-3">
                    <div className="col-11">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input 
                            name="password"
                            value={account.password}
                            onChange={this.handleChange} 
                            type={this.state.showPswd ? "text" :"password"}
                            className="form-control" 
                            id="password" 
                            aria-describedby="passwordHelp" 
                            autoComplete="current-password"
                            ></input>
                    </div>
                    <div className="col-1">
                        <button 
                            type="button"
                            className = "btn btn-light"
                            onClick = {this.toggleShowPassword}
                            >
                            <img 
                                alt = "eye icon"
                                src= {this.state.showPswd ? "../images/eye-crossed.png" : "../images/eye.png"}
                                ></img>
                            </button>
                    </div>
                    <div 
                        id="passwordHelp" 
                        style={ this.state.errors.password ? { color:"red", fontWeight:"bold"}: {}}
                        className="form-text">
                        {this.state.errors.password ? this.state.errors.password : "8-20 characters long"}
                        </div>
                </div>
                    <button 
                        disabled = {this.state.buttonDisable}
                        type="submit" 
                        className="btn bumpBtns"
                        >
                        {this.state.buttonText}
                        </button>
            
        </div>
                </form>
                <br/>
                    <h3 id="message">
                        {this.state.errors.message ? this.state.errors.message : this.state.message }
                    </h3>                
           
        </div>
        )
    
}
}
export default SignUp;