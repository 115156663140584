import React, {Component} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Amplify from '@aws-amplify/core'
//import * as gen from '../services/generated'

import Games from './../games/games';
import HowTo from './howto';
import About from './about';
import NotFound from './notfound';
import Login from './../accounts/login'
import Navigation from './navigation';
import SignUp from './../accounts/signup';
import Account from './../accounts/account';
import LoggedOut from './../accounts/loggedOut';

import * as userService from "../../services/userService";
import * as gameService from "../../services/gameService";

//Amplify.configure(gen.config)

class Router extends Component {
    constructor(props) {
    super(props);

    this.state = {
        boardLoading: false,
        boardHeaderLoading: false,
        loggedIn: false,
        games: [],
        gamesOurTurnCount: 0,
        currentGame: {},
        userObj: {},
        sessionObj: {},
        invitationCounts: {
                from_accepted: 0,
                from_declined: 0,
                from_read: 0,
                from_unread: 0,
                to_accepted: 0,
                to_declined: 0,
                to_read: 0,
                to_unread: 0},
        invitationsMenuItem: "none"
        };
    };
    
    clearInvitationCounts = ()=>{
        this.setState({
            invitationCounts: {
            from_accepted: 0,
                from_declined: 0,
                from_read: 0,
                from_unread: 0,
                to_accepted: 0,
                to_declined: 0,
                to_read: 0,
                to_unread: 0}
            })
    };
    
    clearGameState = ()=>{
        this.setState({
            games: [],
            gamesOurTurnCount: 0,
            currentGame: {}
            });
    }
    
    clearState = ()=>{
        this.setState({
            loggedIn: false,
            userObj: {},
            sessionObj: {}
            });
        this.clearInvitationCounts();
        this.clearGameState();
    }
    
    setBoardLoading = (boolean) => {
        this.setState({boardLoading:boolean})
    }
    
    setBoardHeaderLoading = (boolean) => {
        this.setState({boardHeaderLoading:boolean})
    }
    
    changeCurrentGame = (game_id) => {
        const games=this.state.games
        games.forEach(game=>{
            if (game.game_id === game_id) {
                this.setState({currentGame:game})
            }
        })
    }
    
    setInvitationsMenuItem = (invitationsMenuItem) => {
        this.setState({invitationsMenuItem})
    }
    
    // the purpose of this is update the currentGame with whatever just got pulled
    // in from dynamo. It also counts how many of them are our turn
    // for the number in parentheses at the end of the Games menu.
    // It also handles the case where a currentGame has not already been picked
    setGameState = (games, sessionObj) => {
        // don't forgeT: games might come in as an empty object
        
        // user picks the game. So if there is already a currentGame, just
        // update our state with whatever we pulled from Dynamo for that game
        // except if we iterate through all the games where we are involved and there's no match
        // That can happen if the game in state in CurrentGame was deleted from Dynamo, and in 
        // that case, pick the first game where it's our turn. If there are no games where 
        // it's our turn, pick the first one
        var currentGameB4= null
        if ("currentGame" in this.state) { currentGameB4 = this.state.currentGame}
        var currentGame = null;
        var gamesOurTurnCount = 0;
        if (games.length>0){
                games.forEach(game=>{
                    if (game.turn_user_id === sessionObj.user_id) {
                        gamesOurTurnCount ++;
                        if (currentGame === null){currentGame = game};
                    };
                    if (currentGameB4 !== null){
                        if (game.game_id === currentGameB4.game_id) {
                                currentGame = game;
                            }                        
                    };
                });
            if (currentGame===null){
                currentGame = games[0]
            }
        }
        
        if (games.length===0){currentGame={}}
        // this turns off the "loading..." in the game header and the game boara
        // when what comes back from Dynamo is difference from the current state
        // but only if there actually is at least one game
        if (games.length > 0){
            // check if there is a different selected piece
            if (this.state.currentGame.p1_selected_piece !== currentGame.p1_selected_piece || this.state.currentGame.p2_selected_piece !== currentGame.p2_selected_piece) {this.setState({boardHeaderLoading:false})}
            // check if the board is different because somebody made a move
            if (this.state.currentGame.p1_last_move_date !== currentGame.p1_last_move_date || this.state.currentGame.p2_last_move_date !== currentGame.p2_last_move_date) {this.setState({boardLoading:false})}
        }
        this.setState({
                games,
                gamesOurTurnCount,
                currentGame,
                boardLoading:false,
                
            });
        
    };
    
    // This is called every four seconds
    // it gets the invitation counts and stores them in state
    // and it gets an array of all games that the user is involved with and stores that in state
    // and then it calls setGameState
    getCountsGames = async () => {
      // only connect to back end if someone is logged in...
        var invResult = "";
        var gamesResult = "";
        if (this.state.loggedIn) {
            try{
                var sessionObj = {...this.state.sessionObj};
                invResult = await userService.getInvitationCounts(sessionObj);
                gamesResult = await gameService.getGames(sessionObj);
            }
            catch (error) {
                if ("response" in error){
                    if ("status" in error.response){
                        if (error.response.status === 403) {
                            // pass in false, because we don't want to try to delete
                            // the session from the back end, because we know the 
                            // sessionObj is no good
                            this.handleUserLogOut(false)
                        }
                    }
                }
            }
            
            // this could happen if it times out and there is no response and nothing is returnes. 
            // In that case, just set the them to zero but don't log out the user
            if (invResult === "") {
                this.clearInvitationCounts();
                return
            }
            if (gamesResult === "") {
                this.setState({games:[]});
                return
            }
            if ("foundinvitations" in invResult.headers){
                const invitationCounts = JSON.parse(invResult.headers.foundinvitations);
                this.setState(invitationCounts);
            } else { 
                // and finally, this can happen if there is a reponse, and there is 
                // some kind of error that's not a 403 error and therefore the
                // foundinvitations header is missing. So just clear them out but 
                // don't log out the user
                this.clearInvitationCounts();
            }

            if ("data" in gamesResult){
                // we could get back a 404, which means it successfully queried but didn't find any games
                // for the user. It returns an [] in that case, so no special handling required here.
                const games = gamesResult.data;
                this.setGameState(games, sessionObj);

            } else { 
                this.clearGameState();
            };
        };
    };
    
    componentDidMount() {
        this.getCountsGames()
        this.interval = setInterval(() => this.getCountsGames(), 500);
      };
    
    componentWillUnmount() {
        clearInterval(this.interval);
      };
        
    handleUserObjUpdate = (userObj) => {
        this.setState({userObj});
    };
    
    handlesessionObjUpdate = (sessionObj) => {
        this.setState({sessionObj,loggedIn:true});
        this.getCountsGames();
    };
    
    handleUserLogOut = ( deleteSession = true) => {
        if (deleteSession) {
            // in the event of a bad Obj, this is going to return a 400, so...
            try{
                const userObj = {...this.state.userObj};
                const sessionObj = {...this.state.sessionObj};
                userService.logoffUser(userObj, sessionObj);
            }
            catch{
                // do nothing. I just want to avoid an unhandled exception on the client
            }
        };
        // remove the session from local memory
        this.clearState();
    };
    
    render(){
        return (
            <React.Fragment>
            <React.StrictMode>

              <BrowserRouter>
              <Navigation
                getUserName={this.state.userObj.name}
                getLoggedIn={this.state.loggedIn}
                invitationCounts = {this.state.invitationCounts}
                gamesOurTurnCount = {this.state.gamesOurTurnCount}
                games = {this.state.games}
                getSessionObj={this.state.sessionObj}
                changeCurrentGame={this.changeCurrentGame}
                getInvitationsMenuItem = {this.state.invitationsMenuItem}
                setInvitationsMenuItem= {this.setInvitationsMenuItem}
                onUserLogOut = {this.handleUserLogOut}
                getUserObj={this.state.userObj}
                currentGame = {this.state.currentGame}
                ></Navigation>
                <Routes>
                    <Route path="/" element={<HowTo />}/>
                    <Route exact path="/game" element={
                        <Games
                            onUserLogOut = {this.handleUserLogOut}
                            getLoggedIn={this.state.loggedIn}
                            getSessionObj={this.state.sessionObj}
                            currentGame = {this.state.currentGame}
                            setBoardLoading = {this.setBoardLoading}
                            boardLoading = {this.state.boardLoading}
                            setBoardHeaderLoading = {this.setBoardHeaderLoading}
                            boardHeaderLoading = {this.state.boardHeaderLoading}
                            />}/>
                    <Route exact path="/invitations" element={<div></div>}/>

                    <Route path="/howto" element={<HowTo />}/>
                    <Route path="/about" element={<About />}/>
                    <Route path="/login" element={
                        <Login 
                            onUserObjUpdate={this.handleUserObjUpdate}
                            onSessionObjUpdate={this.handlesessionObjUpdate}
                            loggedIn = {this.state.loggedIn}
                            />}/>
                    <Route path="/signup" element={
                        <SignUp 
                            onUserObjUpdate={this.handleUserObjUpdate}
                            onSessionObjUpdate={this.handlesessionObjUpdate}
                            loggedIn = {this.state.loggedIn}
                            />}/>
                    <Route path="/account" element={
                        <Account 
                            loggedIn = {this.state.loggedIn}
                            onUserLogOut = {this.handleUserLogOut}
                            />}/>
                    <Route path="/loggedOut" element={
                        <LoggedOut />}/>
                    <Route path="*" element={<NotFound />}/>
                </Routes>
              </BrowserRouter>
            </React.StrictMode>
          </React.Fragment>
          )
        };
};

export default Router;