import './gamesSquare.css';
import './../css/pieces.css';
import {getIndexSquareUp, getIndexSquareRight, getIndexSquareDown, getIndexSquareLeft} from '../../services/handyFuncs.js'
import {motion} from "framer-motion";
 
import React, { Component } from 'react';

class GamesSquare extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            opacity: 1,
            animateX: "0vw",
            animateY: "0vw",
            cssZindex: 0,
            duration: 1
        };
    }; 

    toClickOrNotToClick(piece,currentGame,key,x,y){
        // this is the on click handler
        // if up, down, left, or right is selected, and  
        // if it's a playable square with a piece on it,     
        // then that piece has to be animated moving out of the way before actually calling onClick

        // override: if getWinningPlayerID is >0 then someone won, therefore, nothing is clickable
        if (currentGame.winner!==0){
            return () => {};
        }

        var selectedPiece = null;
        if (currentGame.turn_user_id === currentGame.p1_user_id) {selectedPiece = currentGame.p1_selected_piece}
        if (currentGame.turn_user_id === currentGame.p2_user_id) {selectedPiece = currentGame.p2_selected_piece}

        if (selectedPiece !== "x"){
            if (this.isPlayable(currentGame, piece,x,y) && (currentGame.board.find(square => square.pos === key).piece !== "empty")){
                return this.props.onClick(key);
                // Turning off animations for now, until I can figure out a better solution
                // return  this.moveIt(key,piece)
            };     
        };
        // just call onClick right away when the selected piece is empty (e.g. an empty center square)
        if (this.isPlayable(currentGame, piece,x,y) && (currentGame.board.find(square => square.pos === key).piece === "empty")){
            return this.props.onClick(key);
        };
        // if the x is selected
        // and it's a playable square
        //then remove the piece that's there
        if (this.isPlayable(currentGame, piece,x,y) && selectedPiece === "x" ){
            return this.props.onClick(key);
        };
        // all other cases, such as the spot is not playable, don't call onClick
        return () => {};
    };

    // this animates moving the piece out of the way
    moveIt(key,piece){
        this.props.onClick(key);
        switch(piece){
            case "up":
                this.setState({
                    opacity: 1,
                    animateX: 0,
                    animateY: "-4.65vw",
                    cssZindex: 1,
                    duration: .5,
                    scale: 1
                });
                setTimeout(() => {
                    this.setState({ 
                        opacity: 0, 
                        animateX: 0,
                        animateY: "4.65vw",
                        cssZindex: 0,
                        duration: 0,
                        scale: 1
                        })                        
                    }, 500);
                setTimeout(() => {
                    this.setState({ 
                        opacity: 1, 
                        animateX: 0,
                        animateY: 0,
                        cssZindex: 0,
                        duration: 0,
                        scale: 1
                        })
                    },500)
                break;
            case "right":
                this.setState({
                    opacity: 1,
                    animateX: "4.35vw",
                    animateY: 0,
                    cssZindex: 1,
                    duration: .5,
                    scale: 1
                });
                setTimeout(() => {
                    this.setState({ 
                        opacity: 0, 
                        animateX: "-4.35vw",
                        animateY: 0,
                        cssZindex: 0,
                        duration: 0,
                        scale: 1
                        })                        
                    }, 500);
                setTimeout(() => {
                    this.setState({ 
                        opacity: 1, 
                        animateX: 0,
                        animateY: 0,
                        cssZindex: 0,
                        duration: 0,
                        scale: 1
                        })
                    },500)
                break;
            case "down":
                this.setState({
                    opacity: 1,
                    animateX: 0,
                    animateY: "4.65vw",
                    cssZindex: 1,
                    duration: .5,
                    scale: 1
                });
                setTimeout(() => {
                    this.setState({ 
                        opacity: 0, 
                        animateX: 0,
                        animateY: "-4.65vw",
                        cssZindex: 0,
                        duration: 0,
                        scale: 1
                        })                        
                    }, 500);
                setTimeout(() => {
                    this.setState({ 
                        opacity: 1, 
                        animateX: 0,
                        animateY: 0,
                        cssZindex: 0,
                        duration: 0,
                        scale: 1
                        })
                    },500)
                break;
            case "left":
                this.setState({
                    opacity: 1,
                    animateX: "-4.35vw",
                    animateY: 0,
                    cssZindex: 1,
                    duration: .5,
                    scale: 1
                });
                setTimeout(() => {
                    this.setState({ 
                        opacity: 0, 
                        animateX: "4.35vw",
                        animateY: 0,
                        cssZindex: 0,
                        duration: 0,
                        scale: 1
                        })                        
                    }, 500);
                setTimeout(() => {
                    this.setState({ 
                        opacity: 1, 
                        animateX: 0,
                        animateY: 0,
                        cssZindex: 0,
                        duration: 0,
                        scale: 1
                        })
                    },500)
                break;
            default:
                console.log("square: This should never run because there is always a piece");
        }
        
    };

    getRotation(piece,x,y){
        switch(piece){
            case "up":
                return -135;

            case "right":
                return -45;

            case "down":
                return 45;

            case "left":
                return 135;

            case "empty":
                if (this.isCenterSquare(x,y)){
                    return true;
                } else {return false}
            default:
                console.log("square get rotation: This should never run because there is always a piece");

        }
    }


    // classes determine if a square is playable
    // if the selected piece is the x, then the square is only playable if it has a piece that can't be bumped
    //if the selected piece is up, down, left, or right, then the the sqaure is playable if the piece can be bumped (or is an empty center)
    //override: if getWinningPlayerID is greater than 0, then nothing is playable (because someone won!)
    // another override: If it's not your turn, nothing is playable
    getClasses(piece, user_id, currentGame,id, x,y, winningSquare){

        var currentPlayerSelectedPiece=null;
        var playerColor = null;
        
        if (user_id=== currentGame.p1_user_id) {
            currentPlayerSelectedPiece = currentGame.p1_selected_piece;
            playerColor="purple";
        };
        if (user_id=== currentGame.p2_user_id) {
            currentPlayerSelectedPiece = currentGame.p2_selected_piece;
            playerColor="orange";
        };
        
        let classes = "arrow"
        if (piece === "empty" ) {
            classes = classes + " empty " 
            if (this.isCenterSquare(x,y) && winningSquare!=="true" && currentGame.winner===0) {
                const p = (currentPlayerSelectedPiece === "x") ? "" : "playable"
                classes = classes + "centerSquare " + p
            }
        } else {
            // the square is not empty
            classes = classes + " " + piece + " " + playerColor;
            if (this.isPlayable(currentGame, piece,x,y) )  {
                classes = classes +  " playable" 
            };
            if (winningSquare === "true"){
                classes = classes + " winningSquare"
            }
        };

        return classes;

    };

    // if x is 9 or 10 AND y is 9 or 10, then the square is one of the four in the middle
    isCenterSquare(x,y){
        if ((x===9 || x===10) && (y===9 || y===10)) {
            return true
       } else 
       {return false};
    };    

    isPlayable(currentGame, piece,x,y){
        // if the square is not empty, it might be playable if there's an empty spot you can bump the current piece into
        // the middle four squares are always playable when they are empty
        // override: If getWinningPlayerID is not zero then somebody won, so that means nothing is playable
        // another override: If it's not our turn, nothing is playable
        
        const sessionObj = this.props.getSessionObj
        // figure out if it's not our turn...
        // If it's not our turn, nothing is playable
        if (currentGame.turn_user_id !== sessionObj.user_id) {
            return false
        };
        
        // if there's a winner, nothing is playable
        if (currentGame.winner !== 0){
            return false;
        }
        var playable = true;
        switch(piece){
            case "up":
                if (currentGame.board[getIndexSquareUp(currentGame.board,x,y)].piece==="empty"){
                    playable = true;
                } else {playable= false};
                break;
            case "down":
                if (currentGame.board[getIndexSquareDown(currentGame.board,x,y)].piece==="empty"){
                    playable= true;
                } else {playable= false}
                break;
            case "right":
                if (currentGame.board[getIndexSquareRight(currentGame.board,x,y)].piece==="empty"){
                    playable= true;
                } else {playable= false}
                break;
            case "left":
                if (currentGame.board[getIndexSquareLeft(currentGame.board,x,y)].piece==="empty"){
                    playable= true;
                } else {playable= false}
                break;
            case "empty":
                if (this.isCenterSquare(x,y)){
                    playable= true;
                } else {playable= false}
                break;
            default:
                console.log("square: isPlayable: This should never run because there is always a piece");
        };

        // you can only remove a piece if it's not playable
        //therefore, it's simply the not of all the above code
        
        var selectedPiece = null;
        if (currentGame.turn_user_id === currentGame.p1_user_id) {selectedPiece = currentGame.p1_selected_piece}
        if (currentGame.turn_user_id === currentGame.p2_user_id) {selectedPiece = currentGame.p2_selected_piece}
        if (selectedPiece === "x"){
            return !playable;
        } else {
            return playable;
        };

    };

    render() { 
        const {id:key, id, piece, user_id, currentGame, winningSquare}= this.props     
        let x = ~~id.split("-")[0];
        let y = ~~id.split("-")[1];

        return ( 
            <motion.div 
                animate={{x:this.state.animateX, y:this.state.animateY, opacity: this.state.opacity}} 
                transition={{ duration: this.state.duration}}
                style={{rotate: this.getRotation(piece,x,y), zIndex: this.state.cssZindex, position: 'relative'}}
                key={key} 
                id={id} 
                className={this.getClasses(piece, user_id, currentGame, id, x, y, winningSquare)}
                onClick={() => this.toClickOrNotToClick(piece,currentGame,key,x,y)}
            ></motion.div>
        );
    }
    
    
};


 
export default GamesSquare;
